import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import CssBaseline from '@mui/material/CssBaseline/CssBaseline'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { lazy, StrictMode, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { initGA } from './analytics'
import MainSpinner from './components/MainSpinner'
const AppRouter = lazy(() => import('./router/Router'))
const ThemeToggleProvider = lazy(() => import('./theme/ThemeToggleProvider'))
initGA('G-HLS76ZBR1B')

if (import.meta.env.PROD) {
  Sentry.init({
  dsn: 'https://5f67f8573657bff1205340666441259a@o4508222916395008.ingest.de.sentry.io/4508222918099024',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/pay\.pancake\.solutions\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const queryClient = new QueryClient()

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Suspense fallback={<MainSpinner />}>
          <ThemeToggleProvider>
            <CssBaseline />
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </ThemeToggleProvider>
        </Suspense>
      </RecoilRoot>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StrictMode>,
)
