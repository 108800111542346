// analytics.ts
import ReactGA from 'react-ga4'
import { FullParams, ShortParams } from './types/params'


// Initialize GA
export const initGA = (trackingId: string) => {
  ReactGA.initialize(trackingId)
}

// Page view tracking
export const trackPageView = (path: string) => {
  ReactGA.send({hitType: 'pageview', page: path})
}

// Generic event tracking function
export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  })
}

// Payment flow specific events
export const PaymentAnalytics = {
  // Basket page events
  trackAmountChange: (amount: number, currency: string) => {
    trackEvent(
      'Payment',
      'Amount Changed',
      `${currency} ${amount}`,
      Math.round(amount * 100),
    )
  },

  trackCurrencyChange: (fromCurrency: string, toCurrency: string) => {
    trackEvent(
      'Payment',
      'Currency Changed',
      `${fromCurrency} to ${toCurrency}`,
    )
  },

  trackCheckoutStart: (params: FullParams) => {
    trackEvent(
      'Payment',
      'Checkout Started',
      `${params.currency} ${params.amount}`,
      params.amount ? params.amount : 0,
    )
  },

  // Checkout page events
  trackPaymentSubmit: (amount: number, currency: string) => {
    trackEvent(
      'Payment',
      'Payment Submitted',
      `${currency} ${amount}`,
      Math.round(amount * 100),
    )
  },

  trackPaymentError: (errorMessage: string) => {
    trackEvent('Payment', 'Payment Error', errorMessage)
  },

  // Complete page events
  trackPaymentComplete: (status: string, amount: number, currency: string) => {
    trackEvent(
      'Payment',
      'Payment Complete',
      `${status} - ${currency} ${amount}`,
      Math.round(amount * 100),
    )
  },

  // Modal events
  trackModalView: (modalName: string) => {
    trackEvent('Modal', 'View', modalName)
  },

  // Terms events
  trackTermsAccept: () => {
    trackEvent('Terms', 'Accepted', 'Terms and Conditions')
  },

  // URL Parameter tracking
  trackURLParams: (params: ShortParams) => {
    trackEvent(
      'URL',
      'Parameters',
      `org:${params.o},agent:${params.a},currency:${params.c}`,
    )
  },
}
